














import { Component, Prop, Vue } from 'vue-property-decorator';
import Order from '@/data/models/order';
import DateUtils from '@/helpers/date-utils';

@Component({})
export default class DetailOrder extends Vue {
   @Prop({ required: true })
   order: Order;

   get formatDate(): string {
      return DateUtils.toDDMMYYYYHHmm(this.order.dateRequest);
   }

   get displayBags(): string {
      if (!this.order.bagsIds || this.order.bagsIds.length === 0) {
         return this.$t('order.noBag').toString();
      }

      return this.order.bagsIds.join(', ');
   }
}
