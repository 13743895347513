















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Button from '@/components/commons/Button.vue';
import OrderWithDetails from '@/data/models/order-with-details';
import { formatAddress } from '@/helpers/format-string';
import OrderTimeline from '@/components/tracking/OrderTimeline.vue';
import DetailOrder from '@/components/tracking/DetailOrder.vue';
import CartProducts from '@/components/cart/CartProducts.vue';
import CustomerInfo from '@/components/tracking/CustomerInfo.vue';
import OrderProducts from '@/components/tracking/OrderProducts.vue';
import Order from '@/data/models/order';
import { ORDER_SUCCEEDED } from '@/store/mutation-types';
import { EventType, trackEvent } from '@/helpers/analytics';

@Component({
   components: { OrderProducts, CustomerInfo, DetailOrder, OrderTimeline, Button, CartProducts }
})
export default class Tracking extends Vue {
   orderId: string = '';
   order: Order = null;
   orderWithDetails: OrderWithDetails = null;
   currentOrderId: string = '';

   isLoading: boolean = false;
   error: boolean = false;

   mounted() {
      this.orderId = (this.$route.query.orderId as string) ?? '';

      if (this.orderId !== '') {
         this.getOrder();
         this.$store.commit(ORDER_SUCCEEDED);
         trackEvent(EventType.TRACKING_SHOW_ORDER_SUCCESS, { order_id: this.orderId });
      }
   }

   @Watch('order', { immediate: false })
   onOrderChanged() {
      trackEvent(EventType.TRACKING_GET_ORDER_DETAILS, { order_id: this.orderId });

      this.$service.orders
         .getOrderById(this.orderId)
         .then(order => {
            if (order != null) {
               this.orderWithDetails = order;
               this.currentOrderId = this.orderId;

               // Crisp
               this.window.$crisp.push(['set', 'user:nickname', [order.order.clientFullName]]);
               this.window.$crisp.push(['set', 'session:data', ['order_id', order.order.id]]);
               this.window.$crisp.push(['set', 'session:data', ['email', order.order.clientEmail]]);
               this.window.$crisp.push(['set', 'session:data', ['phone', order.order.clientPhone]]);
               this.window.$crisp.push(['set', 'session:data', ['admin_url', String(process.env.VUE_APP_EASY_LAVERIE_ADMIN_URL) + '/orders/' + order.order.id]]);
            } else {
               throw 'no order';
            }

            if (this.$route.query.orderId) {
               this.orderId = this.$route.query.orderId as string;
            }

            const products = [];

            order.products.map(orderProducts => {
               for (let index = 0; index < orderProducts.quantity; index++) {
                  products.push({ product_name: orderProducts.product.name, product_id: orderProducts.product.id });
               }
            });

            if (this.orderId !== '') {
               trackEvent(EventType.CART_PURCHASE, { currency: 'EUR', value: order.totalPrice, relay_id: order.relay.id, products: products });
            }
         })
         .catch(() => (this.error = true))
         .finally(() => (this.isLoading = false));
   }

   get window() {
      return window as any;
   }

   get justOrdered(): boolean {
      return this.$route.query.orderId !== null && this.$route.query.orderId !== undefined;
   }

   formatAddress(): string {
      return formatAddress(this.orderWithDetails?.relay?.place);
   }

   getOrder(): void {
      if (this.orderId !== '') {
         this.error = false;
         this.isLoading = true;
         this.orderWithDetails = null;
         this.currentOrderId = '';

         this.$bind('order', this.$service.orders.orderDoc(this.orderId));
      }
   }
}
