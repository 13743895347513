import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "dayjs/locale/fr";

dayjs.extend(advancedFormat)
dayjs.extend(utc);
dayjs.extend(weekOfYear)
dayjs.locale('fr')

export default class DateUtils {
  private static readonly locale = 'Europe/Paris';

  public static getCurrentLocale(): string {
    return this.locale;
  }

  public static isoStringToBigQueryTimestampFormat(
    input?: string, defaultValue = ''): string {
    if (input) {
      return input.replace('T', ' ');
    }
    if (defaultValue) {
      return this.isoStringToBigQueryTimestampFormat(defaultValue);
    }
    return defaultValue;
  }

  public static isoStringToDate(stringDate: string): Date {
    return dayjs(stringDate).toDate();
  }

  public static toDDMMYYYY(date: Date | string): string {
    if(!date){
      return '';
    }

    if (typeof date == 'string') {
      date = this.isoStringToDate(date);
    }

    return dayjs(date).format('DD/MM/YYYY');
  }

  public static toHHmm(date: (Date | string)) {
    if(!date){
      return '';
    }

    if (typeof date == 'string') {
      date = this.isoStringToDate(date);
    }

    return dayjs(date).format('HH:mm');
  }

  public static toDDMMYYYYHHmm(date: Date): string {
    if(!date){
      return '';
    }

    if (typeof date == 'string') {
      date = this.isoStringToDate(date);
    }

    return dayjs(date).format('DD/MM/YYYY HH:mm');
  }

  public static toWeekNumber(date: (Date | string)) {
    if (typeof date == 'string') {
      date = this.isoStringToDate(date);
    }

    return dayjs(date).locale('fr').format('ww');
  }

  public static toLocalWeekDay(date: (Date | string)) {
    if (typeof date == 'string') {
      date = this.isoStringToDate(date);
    }

    return dayjs(date).locale('fr').format('dddd DD/MM');
  }

  public static toLocalMonthYear(date: (Date | string)) {
    if (typeof date == 'string') {
      date = this.isoStringToDate(date);
    }

    return dayjs(date).locale('fr').format('MMMM YYYY');
  }

  public static toLocalMonth(date: (Date | string)) {
    if (typeof date == 'string') {
      date = this.isoStringToDate(date);
    }

    return dayjs(date).locale('fr').format('MMMM');
  }

  public static intToDay(value: number): string {
    const day: string = dayjs.utc().set('day', value).locale('fr').format('dddd');
    return day[0].toUpperCase() + day.slice(1);
  }

  public static compareBefore(a: string | Date, b: string | Date): boolean {
    if(a === null || b === null) {
      return true;
    }

    if (typeof a == 'string') {
      a = this.isoStringToDate(a);
    }

    if (typeof b == 'string') {
      b = this.isoStringToDate(b);
    }

    return dayjs(a).isBefore(dayjs(b));
  }
}
