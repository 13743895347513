




























import { Component, Prop, Vue } from 'vue-property-decorator';
import OrderWithDetails from '@/data/models/order-with-details';
import { capitalizeFirstLetter, formatPrice } from '@/helpers/format-string';
import Product from '@/data/models/product';

@Component({})
export default class OrderProducts extends Vue {
   @Prop({ required: true })
   order: OrderWithDetails;

   get totalPrice(): string {
      return formatPrice(this.order.totalPrice);
   }

   formatPrice(price: number): string {
      return formatPrice(price);
   }

   getIcon(product: Product) {
      return product.getIcon();
   }

   capitalizeFirstLetter(string: string): string {
      return capitalizeFirstLetter(string);
   }
}
