














import {Component, Prop, Vue} from 'vue-property-decorator';
import Order from '@/data/models/order';

@Component({})
export default class CustomerInfo extends Vue {
  @Prop({required: true})
  order: Order;
}
