










import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrderStatus } from '@/data/models/order';
import { timelineStatus } from '@/constants/order';

@Component({})
export default class OrderTimeline extends Vue {
   @Prop({ required: true })
   currentStatus: OrderStatus;

   get ordersStatus(): number[] {
      return timelineStatus;
   }

   showTip(status: OrderStatus): boolean {
      return this.currentStatus === OrderStatus.PAID && status === OrderStatus.PAID;
   }

   isDone(status: number): boolean {
      return status <= this.currentStatus;
   }
}
